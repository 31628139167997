/* eslint-disable */
import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'

import MyTheme from '../utils/myTheme'

const theme = MyTheme

const useStyles = makeStyles(theme => ({}))

const About = ({ data }) => {
	const classes = useStyles()
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant='h3' align='center'>
						Dana Reinhardt
					</Typography>
				</Grid>

				<Grid item xs={12} sm={4}>
					<Img fluid={data.dana.childImageSharp.fluid} />
				</Grid>
				<Grid item xs={12} sm={8}>
					<Typography variant='body1' color='textPrimary' gutterBottom>
						Dana began her career in the hospitality industry at the age of 12,
						spinning cotton candy and selling corn dogs at the PNE. Her father
						was in the wine importing business for many years and instilled a
						love of wine, food and travel.
						<p>
							Dana’s culinary fate was sealed when, after managing several
							flagship Vancouver restaurants in her early twenties, she followed
							her passion into the kitchen, completing her formal chef’s
							education at <b>Dubrulle Culinary School</b> and launching her
							extremely successful inaugural venture,{' '}
							<b>Cellar Door Restaurant at Sumac Ridge Winery</b> in the
							Okanagan.
						</p>
						<p>
							To deepen her craft further, she completed an apprenticeship at
							the storied <b>River Cafe</b> in London, England, an experience
							that continues to fuel her creatively to this day. Inspired and
							emboldened by what she learned there, she returned to Vancouver
							and launched one of the most innovative and awarded small plates
							restaurants in Vancouver’s culinary history, <b>CRU</b>.
						</p>
					</Typography>
				</Grid>
			</Grid>
		</>
	)
}

export default About

export const query = graphql`
	query {
		dana: file(name: { eq: "dana-one" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 500) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
